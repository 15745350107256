import { Box } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import EpicDropDown from "../../components/epicDropDown";
import RangeDatePicker from "../../components/RangeDatePicker";
import { GlobalData } from "../../context/globalData";
import DoraHeader from "./Header";
import { datePayload } from "../../utils/moduleTabApi";
import axios from "../../utils/axiosHelper";
import { getMetricRange } from "../../utils/getMetricRange";

const Dora = ({ projectId }) => {
  const { projectRangePickerValue } = useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);
  const [doraMetrics, setDoraMetrics] = useState();
  const [range, setRange] = useState();
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });

  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url.trim(), selectedEpic: list });
  };

  const doraMetricsNumbers = () => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/projects/doraMatric/${projectId}?${dateUrl}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        setDoraMetrics(res?.data[0]);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  // get ranges
  useEffect(() => {
    let url = `/api/risks?ProjectId=${projectId}`;
    if (epicData?.url) {
      url += epicData?.url;
    }
    axios
      .get(url)
      .then((result) => {
        setRange(result?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [projectId]);

  useEffect(() => {
    doraMetricsNumbers();
  }, [date]);

  const DeploymentFrequencyRisk = useMemo(
    () =>
      getMetricRange(
        range,
        doraMetrics?.deploymentFrequency,
        "Deployment Frequency"
      ),
    [doraMetrics?.deploymentFrequency, range]
  );
  const ChangeFailureRateRisk = useMemo(
    () =>
      getMetricRange(range, doraMetrics?.changeFailure, "Change Failure Rate"),
    [doraMetrics?.changeFailure, range]
  );
  const DeploymentRollbackRisk = useMemo(
    () =>
      getMetricRange(
        range,
        doraMetrics?.deploymentRollback,
        "Deployment Rollback (Hour)"
      ),
    [doraMetrics?.deploymentRollback, range]
  );
  const MeanLeadTimeRisk = useMemo(
    () =>
      getMetricRange(
        range,
        doraMetrics?.meanLeadTimeChange,
        "Mean Lead Time (Hour)"
      ),
    [doraMetrics?.meanLeadTimeChange, range]
  );

  return (
    <div>
      <Box display={"flex"} gap={2}>
        {/* <EpicDropDown epicDropDownHandler={epicDropDownHandler} />  */}
        <>
          {/* select dropdown of month */}
          {/* <div>
              <NormalSelect
                option={[
                  { label: "Monthly", value: 30 },
                  { label: "Biweekly", value: 14 },
                  { label: "Weekly", value: 7 },
                  // { label: "Daily", value: 1 },
                ]}
                value={iteration}
                handleChange={(value) => setIteration(value)}
              />
            </div> */}
          <div>
            <RangeDatePicker setDate={setDate} date={date} isNextDate={false} />
          </div>
        </>
      </Box>
      <DoraHeader
        riskAndRange={{
          DeploymentFrequencyRisk: {
            risk: DeploymentFrequencyRisk?.RiskCategory,
            from: DeploymentFrequencyRisk?.From?.$numberDecimal,
            to: DeploymentFrequencyRisk?.To?.$numberDecimal,
          },
          ChangeFailureRateRisk: {
            risk: ChangeFailureRateRisk?.RiskCategory,
            from: ChangeFailureRateRisk?.From?.$numberDecimal,
            to: ChangeFailureRateRisk?.To?.$numberDecimal,
          },
          DeploymentRollbackRisk: {
            risk: DeploymentRollbackRisk?.RiskCategory,
            from: DeploymentRollbackRisk?.From?.$numberDecimal,
            to: DeploymentRollbackRisk?.To?.$numberDecimal,
          },
          MeanLeadTimeRisk: {
            risk: MeanLeadTimeRisk?.RiskCategory,
            from: MeanLeadTimeRisk?.From?.$numberDecimal,
            to: MeanLeadTimeRisk?.To?.$numberDecimal,
          },
        }}
        doraMetrics={doraMetrics}
        projectId={projectId}
      />
    </div>
  );
};

export default Dora;
