const PlanningAccuracyStatusList = ["inprogress", "done", "hold"];
const prReivewStatusList = ['open','closed']
const peReivewSubStatus = ['reviewed',"UnReviewed"]
const ResourceAllocationStatusList = [
  "Done",
  "Open",
  "In Progress",
  "Reopened",
  "Resolved",
  "Closed",
  "On Hold",
  "Dev in Progress",
  "QA in Progress",
  "To Do",
  "Ready for Testing",
  "Ready to Upload",
  "Updated Test Server",
  "In Review",
  "Ready for Integration",
  "QA Completed",
  "In QA Pull Request",
  "UAT Product Verify",
  "In Preprod",
  "Ready To Release",
  "Code Merged",
  "Discovery",
  "Parking lot",
  "Ready for delivery",
  "Delivery",
  "Impact",
];
const color = {
  0: "#5EBDCC",
  1: "#F58787",
  2: "#FF8C00",
  3: "#8ADE71",
  4: "#6771dc",
  5: "#FF5733",
  6: "#A3FFD9",
  7: "#FFC0CB",
  8: "#FFD700", // Static color 4
  9: "#00FF00", // Static color 5
  10: "#FF1493", // Static color 6
  11: "#1E90FF", // Static color 7
  12: "#FF4500", // Static color 8
  13: "#8A2BE2", // Static color 9
  14: "#ADFF2F", // Static color 10
  15: "#32CD32", // Static color 11
  16: "#FF8C00", // Static color 12
  17: "#00CED1", // Static color 13
  18: "#DDA0DD", // Static color 14
  19: "#7B68EE", // Static color 15
  20: "#FFEB68", // Static color 16
};

export { PlanningAccuracyStatusList, ResourceAllocationStatusList, color,prReivewStatusList,peReivewSubStatus };
