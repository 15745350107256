import { Card, CardContent, Grid, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { style } from "../Project/projectDashboard/style";
import { RiskColorWrapperWithIcon } from "../../components/overview/riskWrapper";
import InfoIcon from "@material-ui/icons/Info";
import Trend from "../Project/projectDashboard/trend";
import handIcon from "../../assets/twoHandsIcon.png";
import HealthColorWrapper from "../../components/overview/healthColorWrapper";
import IssueLevel from "../Project/projectDashboard/IssueLevel";
import { GlobalData } from "../../context/globalData";

const useStyles = style;

const DoraHeader = ({ doraMetrics, projectId, riskAndRange }) => {
  const classes = useStyles();
  const [issueType, setIssueType] = useState();
  const { setIsDefinaionPopup, setDefinationKPI } = useContext(GlobalData);

  const viewMoreHandler = (name) => {
    setIssueType(name);
  };

  return (
    <div>
      <Grid container spacing={2} className="my-4">
        <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={handIcon} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "24px" }}>
                    {doraMetrics?.deploymentFrequency} Count
                  </h3>
                  <h6>
                    Deployment Frequency (Jenkins){" "}
                    <span
                      onClick={() => {
                        setIsDefinaionPopup(true);
                        setDefinationKPI("Deployment Frequency (Count)");
                      }}
                    >
                      <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
                    </span>
                    <br />
                    <span
                      className={classes.viewMore}
                      onClick={() => viewMoreHandler("Deployment Frequency")}
                    >
                      View More
                    </span>
                  </h6>
                  <Tooltip
                    title={
                      <>
                        <h6>
                          Status: {riskAndRange?.DeploymentFrequencyRisk?.risk}{" "}
                          Risk
                        </h6>
                        <h6>
                          Target:{riskAndRange?.DeploymentFrequencyRisk?.from}
                          (Count) to {riskAndRange?.DeploymentFrequencyRisk?.to}
                          (Count)
                        </h6>
                        <h6>Recommendations :</h6>
                        <ul>
                          <li>Strengthen the Ci/Cd Pipelines.</li>
                          <li>
                            Prioritize automation in testing and deployment.
                          </li>
                          <li>
                            Experiment with smaller, more frequency deployments
                            to gauge impact.
                          </li>
                        </ul>
                      </>
                    }
                  >
                    <div className="metricStatus">
                      <HealthColorWrapper
                        status={riskAndRange?.DeploymentFrequencyRisk?.risk}
                      ></HealthColorWrapper>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={handIcon} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "24px" }}>
                    {" "}
                    {doraMetrics?.changeFailure}%
                  </h3>
                  <h6>
                    Change failure rate (Jenkins )
                    <span
                      onClick={() => {
                        setIsDefinaionPopup(true);
                        setDefinationKPI("Change Failure Rate (%)");
                      }}
                    >
                      <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
                    </span>
                    <br />
                    <span
                      className={classes.viewMore}
                      onClick={() => viewMoreHandler("Change failure rate")}
                    >
                      View More
                    </span>
                  </h6>
                  <Tooltip
                    title={
                      <>
                        <h6>
                          Status: {riskAndRange?.ChangeFailureRateRisk?.risk}{" "}
                          Risk
                        </h6>
                        <h6>
                          Target:{riskAndRange?.ChangeFailureRateRisk?.from}% to{" "}
                          {riskAndRange?.ChangeFailureRateRisk?.to}%
                        </h6>{" "}
                        <h6>Recommendations :</h6>
                        <ul>
                          <li>
                            Analyse changes for patterns: Analyse recent failed
                            changes to pinpoint recurring issues. Identifying
                            patterns in past failures helps address root causes,
                            allowing the team to implement targeted improvements
                            and prevent similar issues from happening again .
                          </li>
                          <li>Foster a culture of collective accountability</li>
                          <li>Embrace automation</li>
                        </ul>
                      </>
                    }
                  >
                    <div className="metricStatus">
                      <HealthColorWrapper
                        status={riskAndRange?.ChangeFailureRateRisk?.risk}
                      ></HealthColorWrapper>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={handIcon} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "24px" }}>
                    {" "}
                    {doraMetrics?.deploymentRollback}(Hours)
                  </h3>
                  <h6>
                    Deployment rollback(Jenkins)
                    <span
                      onClick={() => {
                        setIsDefinaionPopup(true);
                        setDefinationKPI("Deployment Rollback (Hours)");
                      }}
                    >
                      <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
                    </span>
                    <br />
                    <span
                      className={classes.viewMore}
                      onClick={() => viewMoreHandler("Deployment rollback")}
                    >
                      View More
                    </span>
                  </h6>
                  <Tooltip
                    title={
                      <>
                        <h6>
                          Status: {riskAndRange?.DeploymentRollbackRisk?.risk}{" "}
                          Risk
                        </h6>
                        <h6>
                          Target:{riskAndRange?.DeploymentRollbackRisk?.from}
                          (Hours) to {riskAndRange?.DeploymentRollbackRisk?.to}
                          (Hours)
                        </h6>
                        <h6>Recommendations :</h6>
                        <ul>
                          <li>
                            - Perform design reviews to make sure and identify
                            all dependencies.
                          </li>
                          <li>
                            Setup a deployment process to avoid outages like
                            implement blue-green deployments or gradual
                            rollouts.
                          </li>
                          <li>
                            For every rollback, do a RCA and fix the identified
                            issues.
                          </li>
                        </ul>
                      </>
                    }
                  >
                    <div className="metricStatus">
                      <HealthColorWrapper
                        status={riskAndRange?.DeploymentRollbackRisk?.risk}
                      ></HealthColorWrapper>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={handIcon} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "24px" }}>
                    {" "}
                    {doraMetrics?.meanLeadTimeChange}(Hours)
                  </h3>
                  <h6>
                    Mean Lead Time for Change(Jenkins)
                    <span
                      onClick={() => {
                        setIsDefinaionPopup(true);
                        setDefinationKPI("Lead Time for Changes");
                      }}
                    >
                      <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
                    </span>
                    <br />
                    <span
                      className={classes.viewMore}
                      onClick={() =>
                        viewMoreHandler("Mean Lead Time for Change")
                      }
                    >
                      View More
                    </span>
                  </h6>
                  <Tooltip
                    title={
                      <>
                        <h6>
                          Status: {riskAndRange?.MeanLeadTimeRisk?.risk} Risk
                        </h6>
                        <h6>
                          Target:{riskAndRange?.MeanLeadTimeRisk?.from}(Hours)
                          to {riskAndRange?.MeanLeadTimeRisk?.to}(Hours)
                        </h6>
                        <h6>Recommendations :</h6>
                        <ul>
                          <li>Use CI/CD and Continuous Merge</li>
                          <li>
                            Use engineering metrics benchmarks for targeted
                            improvement
                          </li>
                          <li>
                            Streamline communication within and across your
                            teams
                          </li>
                          <li>
                            Create a culture of continuous improvement by
                            scheduling regular retrospectives. In these
                            retrospectives, your team can work collaboratively
                            to identify and address bottlenecks by analyzing
                            past and present development practices.
                          </li>
                        </ul>
                      </>
                    }
                  >
                    <div className="metricStatus">
                      <HealthColorWrapper
                        status={riskAndRange?.MeanLeadTimeRisk?.risk}
                      ></HealthColorWrapper>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {[
        "Deployment Frequency",
        "Change failure rate",
        "Deployment rollback",
        "Mean Lead Time for Change",
      ].includes(issueType) && (
        <Card style={{ marginTop: 20 }}>
          <Trend issueType={issueType} projectId={projectId} />
        </Card>
      )}
      {[
        "Deployment Frequency",
        "Change failure rate",
        "Deployment rollback",
        "Mean Lead Time for Change",
      ].includes(issueType) && (
        <Card style={{ marginTop: 20 }}>
          <IssueLevel
            issueType={issueType}
            assigneeList={[]}
            statusList={[]}
            subStatus={[]}
            projectId={projectId}
            autoScroll={[
              "Deployment Frequency",
              "Change failure rate",
              "Deployment rollback",
              "Mean Lead Time for Change",
            ].includes(issueType)}
            isDate={
              [
                "Deployment Frequency",
                "Change failure rate",
                "Deployment rollback",
                "Mean Lead Time for Change",
              ].includes(issueType)
                ? true
                : false
            }
          />
        </Card>
      )}
    </div>
  );
};

export default DoraHeader;
