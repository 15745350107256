import { Card } from "@material-ui/core";
import React from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import { Box } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import EngineeringIcon from "@mui/icons-material/Engineering";

const WorkAllocationHeader = ({ data }) => {
  return (
    <>
      <Card className="d-flex py-2 px-3 align-items-center">
        <Box className="d-flex">
          <DescriptionIcon />
          <div className="px-2">{data?.ProjectCount} Jira Project</div>
        </Box>
        <Box className="d-flex mx-4">
          <PeopleIcon />
          <div className="px-2">{data?.ResourceCount} People</div>
        </Box>
        <Box className="d-flex mx-4">
          <EngineeringIcon />
          <div className="px-2">{data?.TotalFte} FTE</div>
        </Box>
      </Card>
    </>
  );
};

export default WorkAllocationHeader;
